/*Color Pallette

*/

html {
    background-color: #05081B;
    color: #C6E3ED;
    text-align: center;
    scroll-behavior: smooth;
}

html, body, #root {
    height: 100%;
}

p {
    font-family: 'Raleway', sans-serif;
}