.projectsDiv {
    background-color: #dce8ed;
    min-height: 100%;
}

h2 {
    color: #05081B;
    font-size: 3em;
    font-family: 'Raleway', sans-serif;
    margin-top: 0;

}

h3 {
    color: black;
    font-size: 2em;
    font-family: 'Raleway', sans-serif;
}

.projectImages {
    display: block;
    max-width: 80%;
    height: 50em;
    margin: auto;
    padding-bottom: 15px;
}

p {
    padding-top: 15px;
    margin: auto;
}

.github {
    padding-right: 10px;
}

.projects {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3em;
}
#petsterImage {
    height: 50em;
    margin: auto;
    padding-bottom: 0px;
    width: 28em;
}

#sortingVisualizerImage {
    width: 95%;
    margin: auto;
    max-height: 34em;
    max-width: 50em;
}

#caseHawkImage{

    margin: auto;
    padding-bottom: 0px;
    max-height: 40em;
    max-width: 40em;
}

typography {
    font-size: 1.5em;
}
