.dividingBar {
    height: 6%;
    background-color: #05081B;
}

.about {

    width: 100%;
    background-color: #f5f5f5;
}

h2 {
   position: relative;
    padding-top: 5%;
    color: #05081B;
    font-size: 3em;
    font-family: 'Raleway', sans-serif;
}

.me {
    max-width: 600px;
    width: 95%;
}

p {
    color: #05081B;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 0;
    max-width: 42em;
    padding-bottom: 40px;
}