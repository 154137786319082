.front {
  position: relative;
  height: 100%;
}

.introduction {
  position: absolute;
  font-family: 'Raleway', sans-serif;
  top: 48%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  Width: 100%;
}

.highlightedName {
  color: #42BCF3;
}

.learnMore {
  color: #42BCF3;
}

.backgroundImage {
  position: absolute;
  Width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -99;
}

.logosDiv {
  position: absolute;
  z-index: 100;
  bottom: 5%;
  right: 5%
}

.logos {
  width: 48px;
  margin-left: 24px;

}