#contact {
    color: #C6E3ED;
    margin-bottom: 0;
}

.logosDiv2 {
    bottom: 5%;
}

.logos2 {
    max-width: 48px;
    padding-left: 12px;
    padding-right: 12px;
}

#contactText {
    color: #42BCF3;
    padding-bottom: 40px;
}

#myName {
    color: white;
}

.form-group {
    text-align: left;
}
.form-control {
    height: 30px;
    width: 40%;
    margin-bottom: 15px;
}
#message-box {
    width: 95%;
    height: 90px;
}

label {
    font-size: 1.2em;
}